.featuredInfo{
    box-shadow: 0 0 20px rgba(0,0,0,.25);
}
.protiesfSale{
    height: 150px;
    background-size: 100%;
    border-radius: 8px;
    background-repeat: no-repeat;
}
.protiesfSale:hover{
    box-sizing: border-box;
}
.star{
    background-color: gray;
    font-size: 14px;
    color: yellow;
    padding:0px 3px 3px 3px;
    margin-left: 4px;
    margin-top: 4px;
    border-radius: 5px;
}
.sales{
    background-color: blue;
    color: #fff;
    font-size: 12px;
    padding: 3px 7px;
    font-weight: 700;
    border-radius: 5px;
}
.heartWish{
    background-color: rgb(197, 32, 161);
    color: #fff;
    font-size:10px;
    padding: 3px 7px;
    font-weight: 700;
    border-radius: 5px;
}
.spBorder{
    border: solid 1px #e4d9d9;
    padding: 6px 10px;
    border-radius: 4px;
    transition: all 0.1s ease-in-out;
    overflow: hidden;
    box-shadow: 0 0 20px rgba(0,0,0,.25);
}
.spBorder:hover{
    border: dashed 1px  blue;
}
.locations{
    font-size: 12px;
}
.seprated{
    font-size: 12px;
    background-color: rgba(201, 180, 180, 0.416);
    padding: 1px 3px;
    font-style: italic;
    border-radius: 5px;
}

