.productsCategorySec {
    padding: 30px 0;
    /* background-color: #e3ffcf; */
    background: url("https://png.pngtree.com/background/20210711/original/pngtree-atmospheric-real-estate-effect-synthetic-background-effect-picture-image_1114100.jpg") no-repeat bottom / cover;
}
.productsCategoryItem {
    padding: 15px 20px;
    background-color: #ffffff;
    /* border-right: 1px solid #d5d5d5; */
}
.categoryHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    height: 48px;
}

.categoryHeader .title{
    margin: 0;
    text-transform: capitalize;
    width: 70%;
}
.categoryHeader a {
    display: inline-block;
    text-align: center;
    overflow: hidden;
    z-index: 1;
    background: #8a8a8a;
    text-transform: uppercase;
    cursor: pointer;
    transition: 0.6s;
    font-size: 12px;
    padding: 2px 7px;
    color: #fff;
    border-radius: 4px;
    transition: .4s ease-in-out;
}
.categoryHeader a:hover{
    color: #fff;
    background-color: #fb641b;
}

.categoryBody .singleProducts{
    margin-bottom: 30px;
}
.singleProducts a {
    display: block;
    position: relative;
}
.singleProducts a p {
    color: #444;
    font-size:15px;
    text-transform: capitalize;
    text-align: center;
    line-height: 24px;
    display: none;
}
.singleProducts a img {
    border-radius: 8px;
    /* border: 1px solid #c1c1c1; */
    transition: .4s ease-in-out;
}
.singleProducts a img:hover{
    transform: scale(1.1);
    border: 2px solid yellow;
    box-sizing: border-box;
}
.singleProducts .sale{
    display: inline-block;
    position: absolute;
    top: -10px;
    z-index: 1;
    padding: 2px 10px;
    text-transform: capitalize;
    color: #fff;
    border-radius: 5px;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 1px;
    right: 10px;
    text-align: center;
    background: #fb641b;
    display: none;
}
.apartText{
   font-size: 12px;
   background-color: #8a8a8a;
   display:inline-block;
   padding:5px 8px;
   border-radius: 3px;
   color: #fff;
   margin-top: 18px;
}
    

@media (min-width: 768px) and (max-width: 1199px) {
    .categoryBody {
        justify-content: space-between;
        column-gap: 10px;
    }
    
}