.menuList {
    padding: 15px;
}
.menuList h4 {
    font-size: 18px;
    text-transform: capitalize;
    color: #8a8a8a;
}

.menuList ul {
    padding: 0;
    margin: 0;
}
.menuList ul li a{
    padding: 8px 0;
}

.perent{
    position: relative;
}
.nav-Button{
    width: max-content;
}