.seprate svg{
    font-size: 25px;
    color: rgba(57, 97, 57, 0.619);
}
.seprate >.rentSize{
    font-size: 12px;
}
.seprate > .vari{
    font-size: 12px;
}
.seprate{
    text-align: center;
    border:   1px dotted rgba(50, 236, 13, 0.913);
    border-radius: 4px;
}
.round{
    background-color: black;
    color: #fff;
    padding:0px 3px;
    border-radius: 50px;
}
.protiesfSale{
    overflow:hidden ;
}
.protiesfSale:hover {
        transform: scale(1.1);
        padding-top: 0px;
}