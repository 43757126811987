.plr{
    padding:  40px;
    background-color: #f5f5f5;
}
.parent{
    transition: all .5s ease;
}
.parent:hover{
    border-bottom: 5px solid #004ea2;
    box-shadow: 0 5px 15px rgba(0,0,0,.35);
}
.likee{
    color: darkblue;
}